<template>
  <div>
    <navbar title="申请成为渠道"></navbar>
    <div v-if="userinfo.type == 2 || userinfo.type == 4">
      <div style="text-align: center">
        <img class="img" src="../assets/cg.png" />
      </div>
      <div style="text-align: center; font-size: 0.32rem">申请渠道成功</div>
      <div class="content;font-size: .32rem;">
        尊敬的登录账户名称您好，您的渠道申请已成功提交，请您耐心等待，我们客服将为您升级成为我们的渠道，祝在您在和我们平台今后的合作中鹏程万里。
      </div>
    </div>
    <div v-if="userinfo.type == 3">
      <div style="text-align: center; margin-top: 0.5rem; font-size: 0.32rem">
        您已经是我们的渠道了
      </div>
      <div style="text-align: center; margin-top: 0.8rem; font-size: 0.32rem">
        {{ userinfo.invitation }}
      </div>
    </div>
    <div v-if="userinfo.type == 1 || userinfo.type == 5">
      <div style="text-align: center; margin-top: 0.5rem; font-size: 0.32rem">
        您已经是我们的销售了
      </div>
      <div style="text-align: center; margin-top: 0.8rem; font-size: 0.32rem">
        {{ userinfo.invitation }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
    }),
  },
  methods: {
    ...mapActions(["information_up"]),
  },
  mounted() {
    if (this.userinfo.type == 2) {
      this.information_up({
        id: this.userinfo.id,
        type: 4,
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.img {
  width: 1.28rem;
  height: 128px;
  margin-top: 0.71rem;
}

.content {
  margin-top: 1.6rem;
  padding: 0.3rem;
  box-sizing: border-box;
}
</style>